import { Link } from "react-router-dom";

function Logo({ light }) {
  return (
    <div className="brand-logo mb-0">
      <Link to="/">
        <h1 className={`${light && "logo-dark"} logo`}>
          <span className="company-name">WorkWaveHR</span> | HRMS
        </h1>
      </Link>
    </div>
  );
}

export default Logo;
