import { ADD_MODULE, REMOVE_MODULE, INCREMENT_USERS, DECREMENT_USERS } from "./moduleTypes";

export const addModule = (module) => {
  return {
    type: ADD_MODULE,
    module,
  };
};

export const removeModule = (id) => {
  return {
    type: REMOVE_MODULE,
    id,
  };
};

export const decrementUsers = (id) => {
  return {
    type: DECREMENT_USERS,
    id,
  };
};

export const incrementUsers = (id) => {
  return {
    type: INCREMENT_USERS,
    id,
  };
};