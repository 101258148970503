import { useState } from "react";
import {
  MdOutlineCancel,
  MdArrowBackIosNew,
  MdCancel,
  MdCheckCircle,
  MdPending,
} from "react-icons/md";
import { FaRegFilePdf, FaCopy } from "react-icons/fa";
import styles from "../../styles/Invoice.module.css";
import {
  subscribleClientNow,
  unSubscribleClient,
} from "../../redux/users/userActions";
import { useDispatch } from "react-redux";
import {
  subscription_details,
  selected_modules,
} from "../../selectors/userSelectors";
import { useShallowEqualSelector } from "../../hooks";
import Modal from "../UI/Modal";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import {
  notificationError,
  notificationSuccess,
  formatNumbers,
  convertToCapitalize,
} from "../util/helpers";
import { message as Message, Button } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useGetClientSubscriptionDetails } from "../../redux/hooks/usershookActions";
import { CloseOutlined } from "@ant-design/icons";

function Invoice() {
  const [local_spinner, setLocalSpinner] = useState(false);
  const [sub_enabled, subSetEnabled] = useState(true);
  const [isconfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const user_details = useShallowEqualSelector(subscription_details);

  // eslint-disable-next-line no-unused-vars
  const modules = useShallowEqualSelector(selected_modules);
  const params = queryString.parse(useLocation().search);

  useGetClientSubscriptionDetails(sub_enabled, subSetEnabled, params.bId);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const showConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };
  const showCancelModal = () => {
    setIsCancelModalOpen(true);
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
    setIsCancelModalOpen(false);
  };

  function handlePayNow() {
    setLocalSpinner(true);
    subscribleClientNow(
      dispatch,
      user_details?.payment?.paystack?.authorization_url
    );
    // setIsConfirmModalOpen(false);
  }

  function handleCancelSubscription() {
    const creds = {
      bid: params.bId,
    };
    unSubscribleClient(dispatch, creds).then((res) => {
      if (res.status === "success") {
        notificationSuccess(res.message);
        navigate(`/modules/?bId=${params.bId}`);
      } else if (res.status === "error") {
        notificationError(res.message);
      }
    });
    // setIsCancelModalOpen(false);
  }

  function copyUrl() {
    navigator.clipboard.writeText(window.location.href);
    Message.info("copied to clipboard");
  }

  function savePdf() {
    const domElement = document.querySelector("#container_invoice");
    html2canvas(domElement, {
      onclone: (clonedDoc) => {
        clonedDoc.getElementById("pdf-icon").style.display = "none";
        clonedDoc.getElementById("copy-icon").style.display = "none";
        clonedDoc.getElementById("cancelBtn").style.display = "none";
        clonedDoc.getElementById("payBtn").style.display = "none";
        clonedDoc.getElementById("summary").style.width = "100%";
      },
    }).then((canvas) => {
      const img = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF({ unit: "px" });
      // pdf.addImage(img, "JPEG", 0.2, 0.2, 8, 10);
      pdf.addImage(img, "JPEG", 12, 10, 410, 600);
      pdf.save("proformainvoice_cloudletHr.pdf");
    });
  }
  return (
    <div className="container-scroller invoice-page">
      <div className="row">
        <div className={`${styles.invoice} col-12 col-lg-4 mx-auto`}>
          <div className="invoice-content">
            <div className="return-btn text-start mt-5 mb-2">
              <div
                onClick={() => {
                  window.history.back();
                }}
                className="text-white d-flex gap-2 align-items-start ml-3 ml-md-0"
                style={{ cursor: "pointer" }}
              >
                <MdArrowBackIosNew className="h3" /> Back to modules
              </div>
            </div>
            <div id="container_invoice">
              <h1 className="brand-logo logo d-md-none mb-2 ">
                <span className="company-name">WorkWaveHR</span> | HRMS
              </h1>
              <div className="d-flex justify-content-between align-items-center px-0">
                <div>
                  <p className="mb-0 h4">Proforma Invoice</p>
                  <small className="mb-0 h6">
                    <em>By: WorkWaveHR solutions</em>
                  </small>
                </div>
                <div className="brand-logo logo d-none d-md-flex">
                  <span className="company-name">WorkWaveHR</span> | HRMS
                </div>
              </div>
              <div className={styles.company_details}>
                <p className="mb-2 h6">
                  <em>To Customer:</em>
                </p>
                <h3 className="mb-2 mb-md-2">
                  {user_details?.business?.company_name &&
                    convertToCapitalize(user_details?.business?.company_name)}
                </h3>
              </div>
              <div
                className="d-flex justify-content-center gap-3"
                id="share-icons"
              >
                <span
                  className={styles.shareIcon}
                  title="copy URL"
                  id="copy-icon"
                >
                  <FaCopy onClick={copyUrl} />
                </span>
                <span
                  className={styles.shareIcon}
                  title="print invoice"
                  id="pdf-icon"
                >
                  <FaRegFilePdf onClick={savePdf} />
                </span>
              </div>
              <p className="text-muted mt-3 mb-0 mb-lg-3 h5">Details:</p>
              <div className="d-flex flex-column flex-lg-row justify-content-lg-end w-100">
                <div className={styles.summary} id="summary">
                  <div className="d-flex justify-content-between">
                    <p className="text-muted mb-2">Amount:</p>
                    <p className="mb-1">
                      <span>
                        {user_details?.payment?.amount &&
                          formatNumbers(user_details?.payment?.amount)}
                      </span>{" "}
                      {user_details?.payment?.currency}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="text-muted mb-2">Fee:</p>
                    <p className="mb-1">
                      <span>{formatNumbers(user_details?.payment?.fees)}</span>{" "}
                      {user_details?.payment?.currency}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="text-muted mb-2">Channel:</p>
                    <p className="mb-1">
                      {user_details?.payment?.channel &&
                        convertToCapitalize(user_details?.payment?.channel)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="text-muted mb-2">Modules:</p>
                    <p className="mb-1">
                      {user_details?.payment?.no_of_modules}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="text-muted mb-2">Total Amount:</p>
                    <p className="mb-1">
                      {formatNumbers(
                        user_details?.payment?.amount +
                          user_details?.payment?.fees
                      )}{" "}
                      {user_details?.payment?.currency}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center gap-2 ms-lg-auto ml-3">
                  <div className="d-flex gap-5 gap-md-2">
                    <div id="payBtn">
                      <Button
                        type="text"
                        onClick={showConfirmModal}
                        className={`${styles.payBtn} text-success`}
                        // loading={local_spinner}
                        size="large"
                      >
                        <img
                          src="/assets/money.png"
                          alt="paystack"
                          className={styles.paystack}
                          width={50}
                        />{" "}
                        <span>Pay Now</span>
                      </Button>
                      {isconfirmModalOpen && (
                        <Modal className={styles.modal} onClose={handleCancel}>
                          <CloseOutlined
                            className={styles.closeIcon}
                            onClick={handleCancel}
                          />
                          <div className={styles.modalImage}>
                            <ul>
                              <li className={styles.modalDone}>
                                <MdCheckCircle />
                                Select Modules
                              </li>
                              <li className={styles.modalDone}>
                                <MdCheckCircle />
                                Select Payment Method
                              </li>
                              <li className={styles.pending}>
                                <MdPending />
                                Make Payment
                              </li>
                              <li>
                                <MdCancel />
                                Approve Payment
                              </li>
                              <li>
                                <MdCancel />
                                Account Creation
                              </li>
                              <li>
                                <MdCancel />
                                Manage Account
                              </li>
                            </ul>
                          </div>
                          <div className={styles.modalContent}>
                            <h3>
                              {user_details?.business?.company_name &&
                                convertToCapitalize(
                                  user_details?.business?.company_name
                                )}
                            </h3>
                            <p>Do you wish to continue this payment?</p>
                            <div className={styles.modalBtns}>
                              <Button
                                className={styles.decline}
                                onClick={handleCancel}
                              >
                                No
                              </Button>
                              <Button
                                className={styles.approve}
                                onClick={handlePayNow}
                                loading={local_spinner}
                              >
                                Yes
                              </Button>
                            </div>
                          </div>
                        </Modal>
                      )}
                    </div>
                    <div id="cancelBtn">
                      <Button
                        type="text"
                        onClick={showCancelModal}
                        className={` ${styles.cancelBtn} text-danger`}
                        size="large"
                      >
                        <MdOutlineCancel /> Cancel
                      </Button>
                      {isCancelModalOpen && (
                        <Modal className={styles.modal} onClose={handleCancel}>
                          <CloseOutlined
                            className={styles.closeIcon}
                            onClick={handleCancel}
                          />
                          <div className={styles.modalImage}>
                            <ul>
                              <li className={styles.modalDone}>
                                <MdCheckCircle />
                                Select Modules
                              </li>
                              <li className={styles.modalDone}>
                                <MdCheckCircle />
                                Select Payment Method
                              </li>
                              <li className={styles.pending}>
                                <MdPending />
                                Make Payment
                              </li>
                              <li>
                                <MdCancel />
                                Approve Payment
                              </li>
                              <li>
                                <MdCancel />
                                Account Creation
                              </li>
                              <li>
                                <MdCancel />
                                Manage Account
                              </li>
                            </ul>
                          </div>
                          <div className={styles.modalContent}>
                            <h3>
                              {user_details?.business?.company_name &&
                                convertToCapitalize(
                                  user_details?.business?.company_name
                                )}
                            </h3>
                            <p>Do you wish to cancel this payment?</p>
                            <div className={styles.modalBtns}>
                              <Button
                                className={styles.decline}
                                onClick={handleCancel}
                              >
                                No
                              </Button>
                              <Button
                                className={styles.approve}
                                onClick={handleCancelSubscription}
                                loading={local_spinner}
                              >
                                Yes
                              </Button>
                            </div>
                          </div>
                        </Modal>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" d-flex flex-column my-3">
                <h5>Summary</h5>
                <table className="my-3 w-100">
                  <thead className="bg-light">
                    <th>S/N</th>
                    <th className="text-start">Module</th>
                    <th className="text-start pr-3">Users</th>
                    <th className="text-start pr-1">Unit Cost</th>
                    <th className="text-start">
                      Total ({user_details?.payment?.currency})
                    </th>
                  </thead>
                  <tbody>
                    {user_details?.modules?.map((item, index) => {
                      return (
                        <tr key={item.module.id}>
                          <td>{index + 1}</td>
                          <td>{item.module.name}</td>
                          <td>{item.no_of_users}</td>
                          <td>{formatNumbers(item.module.pricing)}</td>
                          <td>
                            {formatNumbers(
                              item.module.pricing * item.no_of_users
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
