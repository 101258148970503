import { createSelector } from "reselect";

const user_message = createSelector(
  (state) => state.user,
  (user) => user.message
);

const user_status = createSelector(
  (state) => state.user,
  (user) => user.status
);

const user_spinner = createSelector(
  (state) => state.user,
  (user) => user.spinner
);
const user_errors = createSelector(
  (state) => state.user,
  (user) => user.errors
);
const system_modules = createSelector(
  (state) => state.user,
  (user) => user.system_modules
);
const selected_modules = createSelector(
  (state) => state.modules,
  (modules) => modules.modules
);
const subscription_details = createSelector(
  (state) => state.user,
  (user) => user.subscription_details
);
const selected_modules_id = createSelector(
  (state) => state.user,
  (user) => user.sel_id
);
const selected_channels = createSelector(
  (state) => state.user,
  (user) => user.sel_channel
);
const current_business_id = createSelector(
  (state) => state.user,
  (user) => user.business_id
);
const sel_durations = createSelector(
  (state) => state.user,
  (user) => user.duration
);
const no_of_users = createSelector(
  (state) => state.user,
  (user) => user.no_of_users
);
const company_email = createSelector(
  (state) => state.user,
  (user) => user.company_email
);
const filter_text = createSelector(
  (state) => state.user,
  (user) => user.filter_text
);

export {
  user_message,
  user_status,
  user_spinner,
  user_errors,
  system_modules,
  selected_modules,
  subscription_details,
  selected_modules_id,
  selected_channels,
  current_business_id,
  sel_durations,
  no_of_users,
  company_email,
  filter_text,
};
