// import Date from "./CustomDate";

import { A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/bundle";

const IMAGES = [
  {
    id: 1,
    url: "/assets/landing-page/Booking Management.png",
    name: "Booking Management",
  },
  {
    id: 2,
    url: "/assets/landing-page/Human Resource Management.png",
    name: "Human Resource Management",
  },
  {
    id: 3,
    url: "/assets/landing-page/iluustration-02.png",
    name: "Reports Management",
  },
  {
    id: 4,
    url: "/assets/landing-page/Leave Management.png",
    name: "Leave Management",
  },
  {
    id: 5,
    url: "/assets/landing-page/Payroll managementpng.png",
    name: "Payroll Management",
  },
  {
    id: 6,
    url: "/assets/landing-page/Performance Management-06.png",
    name: "Performance Management",
  },
  {
    id: 7,
    url: "/assets/landing-page/Performance Management.png",
    name: "Perfomance Management",
  },
  {
    id: 8,
    url: "/assets/landing-page/Task Management.png",
    name: "Task Management",
  },
  {
    id: 9,
    url: "/assets/landing-page/Shift Management.png",
    name: "Shift Management",
  },
  {
    id: 10,
    url: "/assets/landing-page/Training Management 03.png",
    name: "Training Management",
  },
];

function Sidebar({ dark }) {
  return (
    <aside
      className={`col-5 d-none d-lg-flex flex-column gap-5 justify-content-center align-items-center p-3 ${
        dark && "text-light"
      }`}
    >
      <div className="mb-5">
        {/* <div className="mb-4"> */}
        {/* <Date /> */}
        {/* <p className="my-2 text-center">Lagos, Nigeria</p> */}
        {/* </div> */}
        <div className="image-holder">
          <Swiper
            modules={[A11y, Autoplay]}
            slidesPerView={1}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            loop={true}
          >
            {IMAGES.map(({ id, url, name }) => (
              <SwiperSlide key={id}>
                <img src={url} alt={name} />
                <p className="text-center text-muted">{name}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
