import { useSelector } from "react-redux";
import "../../styles/ModulesDetail.css";
import { MdDownloading } from "react-icons/md";
import { useShallowEqualSelector } from "../../hooks";
import { no_of_users, subscription_details } from "../../selectors/userSelectors";
import { formatNumbers } from "../util/helpers";
import CartModule from "../UI/CartModule";

function ModulesDetail() {
  const selectedModule = useSelector((state) => state.modules.modules);
  const selectedModuleTotal = useSelector((state) => state.modules.totalAmount);
  const noOfUsers = useShallowEqualSelector(no_of_users) 
  const subscriptionDetails = useShallowEqualSelector(subscription_details);

  return (
    <>
      <div className="module-detail d-block col py-0">
        <div className="px-3 pt-2 d-flex justify-content-between align-items-center">
          <div className="heading">
            <p className="my-1">
              Total Employees:{" "}
              <span>{subscriptionDetails?.business?.no_of_users || noOfUsers}</span>
            </p>
            <p className="my-1">
              Estimated Fee:{" "}
              <span className="text-bold">
                {formatNumbers(selectedModuleTotal)}
              </span>{" "}
              NGN
            </p>
          </div>
          <div className="icon m-0">
            <MdDownloading />
            <span>{selectedModule.length}</span>
          </div>
        </div>
        <hr />
        <div className="content">
          {selectedModule.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center no-content">
              <p className="text-muted">No Modules Selected</p>
            </div>
          ) : (
            <ul>
              {selectedModule.map(({ id, badge, name, price, no_of_users, flag }) => (
                <CartModule
                  key={id}
                  badge={badge}
                  id={id}
                  name={name}
                  price={price}
                  no_of_users={no_of_users}
                  flag={flag}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default ModulesDetail;
