import { useState, useEffect } from "react";
import {
  addModule,
  removeModule,
  incrementUsers,
  decrementUsers,
} from "../../redux";
import Modal from "./Modal";
import { useSelector, useDispatch } from "react-redux";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useShallowEqualSelector } from "../../hooks";
import {
  no_of_users,
  subscription_details,
} from "../../selectors/userSelectors";
import { formatNumbers } from "../util/helpers";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Input, Radio, Space } from "antd";

import styles from "../../styles/SystemModule.module.css";

function SystemModule({
  id,
  image,
  badge,
  name,
  price,
  desc,
  currency,
  symbol,
  flag,
  to_cart,
}) {
  const selectedModule = useSelector((state) => state.modules.modules);
  const subscriptionDetails = useShallowEqualSelector(subscription_details);
  const noOfUsers = useShallowEqualSelector(no_of_users);
  const params = queryString.parse(useLocation().search);

  const [showModal, setShowModal] = useState(false);
  const [numOfUsers, setNumOfUSers] = useState(
    subscriptionDetails?.business?.no_of_users || noOfUsers
  );
  const [value, setValue] = useState(1);

  const dispatch = useDispatch();

  const module = {
    id,
    name,
    price,
    desc,
    badge,
    image,
    no_of_users: numOfUsers,
    symbol,
    currency,
    flag,
  };

  const exisitingModule = selectedModule.find((module) => module.id === id);

  // console.log(exisitingModule);

  useEffect(() => {
    console.log(params?.short_name);
    if (params?.short_name === undefined) {
      if (module.flag !== null) dispatch(addModule(module));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (to_cart) dispatch(addModule(module));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subscriptionDetails) {
      const user = subscriptionDetails?.business?.no_of_users;

      setNumOfUSers(user);
    }
  }, [subscriptionDetails]);

  const changeHandler = (e) => {
    setNumOfUSers(e.target.value);
  };
  const changeHandlerCart = (e) => {
    // setNumOfUSers(e.target.value);
    const can_no_of_users = parseInt(
      subscriptionDetails?.business?.no_of_users
    );
    let new_no_of_users = parseInt(e.target.value) || can_no_of_users;

    if (new_no_of_users > can_no_of_users) {
      new_no_of_users = can_no_of_users;
    }

    module.no_of_users = new_no_of_users;
    // console.log(module);
    dispatch(addModule(module));
  };

  const addHandler = () => {
    dispatch(addModule(module));
    setShowModal(false);
  };

  const removeHandler = () => {
    dispatch(removeModule(id));
  };

  // const incrementBtnDisabled =
  //   numOfUsers >= subscriptionDetails?.business?.no_of_users;

  // const decrementBtnDisabled = numOfUsers <= 1;

  // const handleIncrement = () => {
  //   setNumOfUSers(numOfUsers + 1);
  // };

  // const handleDecrement = () => {
  //   setNumOfUSers(numOfUsers - 1);
  // };
  // const handleIncrementCart = () => {
  //   dispatch(incrementUsers(id));
  // };

  // const handleDecrementCart = () => {
  //   dispatch(decrementUsers(id));
  // };

  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <>
      <div className="col-12 col-lg-4 col-md-6 p-3">
        <div className={styles.card} onClick={() => setShowModal(true)}>
          <div className={styles.moduleImage}>
            <img src={image} alt={name} />
          </div>
          <div className={styles.moduleName}>
            <h4>{name}</h4>
          </div>
          <div
            className={
              selectedModule.find((module) => module.id === id)
                ? `${styles.badge} ${styles.selected}`
                : styles.badge
            }
          >
            {badge}
          </div>
        </div>
      </div>
      {showModal && (
        <Modal className={styles.modal} onClose={() => setShowModal(false)}>
          <div className={styles.image}>
            <img src={image} alt={name} />
          </div>
          <div className={styles.details}>
            <h3 className={styles.modalHeading}>{name}</h3>
            <small className={styles.modalBadge}>{badge}</small>
            <p className={styles.modalContent}>{desc}</p>
            <div className={styles.modalBase}>
              <p className={`${styles.modalPrice} m-0`}>
                <span dangerouslySetInnerHTML={{ __html: symbol }}></span>
                {price} per employee
              </p>
              <form onSubmit={addHandler}>
                {name === "Performance Management" && (
                  <div
                    className={`${styles.input} flex-column align-items-start mb-3`}
                  >
                    <label htmlFor="number-of-users" className="mb-0">
                      Choose Your Preffered Performance Management Tool
                    </label>
                    <div className="d-flex gap-3 gap-md-2">
                      <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                          <Radio value={1}>Management by Objective (MBO)</Radio>
                          <Radio value={2}>Balance Score card (BSC)</Radio>
                          <Radio value={3}>
                            Objectives and Key Results (OKR)
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </div>
                  </div>
                )}

                <div className={styles.input}>
                  <label htmlFor="number-of-users" className="mb-0">
                    Number of Employees
                  </label>
                  <div className="d-flex gap-3 gap-md-2">
                    {/* {exisitingModule ? (
                      <button
                        className={styles.btn}
                        type="button"
                        disabled={exisitingModule.no_of_users <= 1}
                        onClick={handleDecrementCart}
                      >
                        <MinusOutlined />
                      </button>
                    ) : (
                      <button
                        className={styles.btn}
                        type="button"
                        disabled={decrementBtnDisabled || flag}
                        onClick={handleDecrement}
                      >
                        <MinusOutlined />
                      </button>
                    )} */}

                    {exisitingModule ? (
                      <input
                        type="number"
                        name="number-of-users"
                        onChange={changeHandlerCart}
                        value={exisitingModule.no_of_users}
                        defaultValue={exisitingModule.no_of_users}
                        readOnly
                      />
                    ) : (
                      <input
                        type="number"
                        name="number-of-users"
                        onChange={changeHandler}
                        value={numOfUsers}
                        readOnly
                        disabled={
                          selectedModule.find((module) => module.id === id) ||
                          flag
                        }
                      />
                    )}

                    {/* {exisitingModule ? (
                      <button
                        className={styles.btn}
                        type="button"
                        disabled={
                          exisitingModule.no_of_users >=
                          subscriptionDetails?.business?.no_of_users
                        }
                        onClick={handleIncrementCart}
                      >
                        <PlusOutlined />
                      </button>
                    ) : (
                      <button
                        className={styles.btn}
                        type="button"
                        disabled={incrementBtnDisabled || flag}
                        onClick={handleIncrement}
                      >
                        <PlusOutlined />
                      </button>
                    )} */}
                  </div>
                </div>
              </form>
              {exisitingModule ? (
                <div>
                  <h3 className="h3">
                    Total:{" "}
                    <span dangerouslySetInnerHTML={{ __html: symbol }}></span>
                    {formatNumbers(
                      Number(exisitingModule.price) *
                        Number(exisitingModule.no_of_users)
                    )}{" "}
                    {currency} <small className="h5git push">per annum</small>
                  </h3>
                </div>
              ) : (
                <div>
                  <h3 className="h3">
                    Total:{" "}
                    <span dangerouslySetInnerHTML={{ __html: symbol }}></span>
                    {formatNumbers(price * Number(numOfUsers))} {currency}{" "}
                    <small className="h5git push">per annum</small>
                  </h3>
                </div>
              )}
            </div>
            <div className={styles.modalFooter}>
              <button
                className={styles.close}
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              {exisitingModule ? (
                <button
                  className={styles.cta}
                  onClick={removeHandler}
                  disabled={flag}
                >
                  Remove from Cart
                </button>
              ) : (
                <button
                  className={styles.cta}
                  onClick={addHandler}
                  disabled={
                    numOfUsers > subscriptionDetails?.business?.no_of_users ||
                    numOfUsers < 1
                  }
                >
                  Add to Cart
                </button>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default SystemModule;
