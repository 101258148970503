import { combineReducers } from "redux";
import moduleReducer from "./modules/modulesReducer";
import userReducer from "./users/userReducer";

const rootReducer = combineReducers({
  modules: moduleReducer,
  user: userReducer,
});

export default rootReducer;
