import validator from "validator";

// Validation for login page

export const loginValidator = (values) => {
  let errors = {};

  if (values.hasOwnProperty("email") && validator.isEmpty(values.email)) {
    errors.email = "Email must not be empty";
  } else if (
    values.hasOwnProperty("email") &&
    !validator.isEmail(values.email)
  ) {
    errors.email = "Please enter a valid email address";
  }

  if (values.hasOwnProperty("password") && validator.isEmpty(values.password)) {
    errors.password = "Password must not be empty";
  }

  return errors;
};

// Validation for register page

export const registerValidator = (values) => {
  let errors = {};

  if (
    values.hasOwnProperty("company_name") &&
    validator.isEmpty(values.company_name)
  ) {
    errors.company_name = "Company name is required";
  }

  if (
    values.hasOwnProperty("company_email") &&
    validator.isEmpty(values.company_email)
  ) {
    errors.company_email = "Company email is required";
  } else if (
    values.hasOwnProperty("company_email") &&
    !validator.isEmail(values.company_email)
  ) {
    errors.company_email = "Please enter a valid email address";
  }

  if (
    values.hasOwnProperty("no_of_users") &&
    validator.isEmpty(values.no_of_users)
  ) {
    errors.no_of_users = "Number of users is required";
  }

  if (values.hasOwnProperty("site_url") && validator.isEmpty(values.site_url)) {
    errors.site_url = "Site address is required";
  }

  return errors;
};

// Validation for forgot password page

export const forgotValidator = (values) => {
  let errors = {};

  if (values.hasOwnProperty("email") && validator.isEmpty(values.email)) {
    errors.email = "Email cannot be empty";
  } else if (
    values.hasOwnProperty("email") &&
    !validator.isEmail(values.email)
  ) {
    errors.email = "Please enter a valid email address";
  }

  return errors;
};

// Validation for reset page

export const resetValidator = (values) => {
  let errors = {};

  if (values.hasOwnProperty("password") && values.password.trim() === "") {
    errors.password = "Password must not be empty";
  }

  if (
    values.hasOwnProperty("confirm_password") &&
    values.confirm_password.trim() === ""
  ) {
    errors.confirm_password = "Password must not be empty";
  }

  if (
    Object.keys(errors).length === 0 &&
    values.hasOwnProperty("confirm_password") &&
    values.hasOwnProperty("password") &&
    values.password !== values.confirm_password
  ) {
    errors.confirm_password = "Passwords do not match!";
  }

  return errors;
};
