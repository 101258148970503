import {
  ADD_MODULE,
  REMOVE_MODULE,
  INCREMENT_USERS,
  DECREMENT_USERS,
} from "./moduleTypes";

const current_cart = window.localStorage.getItem("cart");
let c_modules = [];
let c_totalAmount = 0;

if (current_cart) {
  c_modules = JSON.parse(current_cart).modules;
  c_totalAmount = JSON.parse(current_cart).totalAmount;
}

const initialState = {
  modules: c_modules,
  totalAmount: c_totalAmount,
};

const moduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MODULE: {
      let newTotalAmount = 0;

      console.log({ ok: action.module, mo: state.modules });

      const existingModuleItemIndex = state.modules.findIndex(
        (module) =>
          module.id === action.module.id || module.badge === action.module.badge
      );
      const existingModuleItem = state.modules[existingModuleItemIndex];

      let updatedModules;

      if (existingModuleItem) {
        const updatedModule = { ...action.module };
        updatedModules = [...state.modules];
        updatedModules[existingModuleItemIndex] = updatedModule;
      } else {
        updatedModules = [...state.modules].concat(action.module);
      }

      updatedModules.forEach((module) => {
        newTotalAmount =
          newTotalAmount + Number(module.price) * Number(module.no_of_users);
      });

      window.localStorage.setItem(
        "cart",
        JSON.stringify({
          ...state,
          modules: updatedModules,
          totalAmount: newTotalAmount,
        })
      );

      return {
        ...state,
        modules: updatedModules,
        totalAmount: newTotalAmount,
      };
    }

    case INCREMENT_USERS:
      const indexExistingModule = state.modules.findIndex(
        (module) => module.id === action.id
      );

      const presentModuleItem = state.modules[indexExistingModule];

      const newNumOfUsers = Number(presentModuleItem.no_of_users) + 1;

      const freshTotalAmount =
        Number(state.totalAmount) + presentModuleItem.price;

      let modulesUpdated;

      if (presentModuleItem) {
        const updatedModule = {
          ...presentModuleItem,
          no_of_users: newNumOfUsers,
        };
        modulesUpdated = [...state.modules];
        modulesUpdated[indexExistingModule] = updatedModule;
      }

      // else {
      //   updatedModules = state.modules.concat(presentModuleItem);
      // }

      window.localStorage.setItem(
        "cart",
        JSON.stringify({
          ...state,
          modules: modulesUpdated,
          totalAmount: freshTotalAmount,
        })
      );

      return {
        ...state,
        modules: modulesUpdated,
        totalAmount: freshTotalAmount,
      };

    case REMOVE_MODULE: {
      const existingModuleIndex = state.modules.findIndex(
        (module) => module.id === action.id
      );

      const existingModule = state.modules[existingModuleIndex];
      let updatedTotalAmount = 0;

      // state.totalAmount - existingModule.price * existingModule.no_of_users;

      let newUpdatedModules;

      if (existingModule) {
        newUpdatedModules = state.modules.filter(
          (module) => module.id !== action.id
        );
      } else {
        const updatedModule = {
          ...existingModule,
        };
        newUpdatedModules = [...state.modules];
        newUpdatedModules[existingModuleIndex] = updatedModule;
      }

      newUpdatedModules.forEach((module) => {
        updatedTotalAmount =
          updatedTotalAmount +
          Number(module.price) * Number(module.no_of_users);
      });

      window.localStorage.setItem(
        "cart",
        JSON.stringify({
          ...state,
          modules: newUpdatedModules,
          totalAmount: updatedTotalAmount,
        })
      );

      return {
        ...state,
        modules: newUpdatedModules,
        totalAmount: updatedTotalAmount,
      };
    }

    case DECREMENT_USERS:
      const indexOfExistingModule = state.modules.findIndex(
        (module) => module.id === action.id
      );

      const foundModuleItem = state.modules[indexOfExistingModule];

      const updatedNumOfUsers = Number(foundModuleItem.no_of_users) - 1;

      const subtractedTotalAmount = state.totalAmount - foundModuleItem.price;

      let newModules;

      if (foundModuleItem) {
        const updatedModule = {
          ...foundModuleItem,
          no_of_users: updatedNumOfUsers,
        };
        newModules = [...state.modules];
        newModules[indexOfExistingModule] = updatedModule;
      }

      // else {
      //   updatedModules = state.modules.concat(foundModuleItem);
      // }

      window.localStorage.setItem(
        "cart",
        JSON.stringify({
          ...state,
          modules: newModules,
          totalAmount: subtractedTotalAmount,
        })
      );

      return {
        ...state,
        modules: newModules,
        totalAmount: subtractedTotalAmount,
      };

    default:
      return state;
  }
};

export default moduleReducer;
