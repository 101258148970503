import { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";

function useForm(callback, initState = {}, validate) {
  const [values, setValues] = useState(initState);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setValues((prevState) => {
      return { ...prevState, [e?.target.name]: e?.target.value };
    });
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    const get_error = validate(values);

    if (Object.keys(get_error).length === 0) {
      callback();
      setErrors(get_error);
    } else {
      setErrors(get_error);
    }
  };

  return { handleChange, handleSubmit, errors, values };
}

function useShallowEqualSelector(selector) {
  return useSelector(selector, shallowEqual);
}

function useDebounce(callback, delay, dependencies) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, [clear]);
}

function useTimeout(callback, delay) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

function useLocalStorage(key, defaultValue) {
  return useStorage(key, defaultValue, window.localStorage);
}
function useSessionStorage(key, defaultValue) {
  return useStorage(key, defaultValue, window.sessionStorage);
}

function useStorage(key, defaultValue, storageObject) {
  const [value, setValue] = useState(() => {
    const jsonValue = storageObject.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);

    if (typeof defaultValue === "function") {
      return defaultValue();
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (value === undefined) return storageObject.removeItem(key);
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value, storageObject]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
}

export {
  useForm,
  useShallowEqualSelector,
  useDebounce,
  useTimeout,
  useWindowSize,
  useLocalStorage,
  useSessionStorage,
};
