import { request } from "../../requestMethods";

const registerClient = async (dispatch, creds) => {
  try {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    dispatch({ type: "START_SPINNER" });
    let result = await request.post("/users/register", creds);
    result = result.data;
    result.status = "success";
    dispatch({ type: "STOP_SPINNER" });
    dispatch({ type: "USER_REGISTRATION_SUCCESS", payload: result });
    dispatch({ type: "SAVE_NO_OF_EMPLOYEES", payload: creds.no_of_users });
    dispatch({ type: "SAVE_COMPANY_EMAIL", payload: creds.company_email });

    return result;
  } catch (error) {
    dispatch({ type: "STOP_SPINNER" });
    let resMessage = error?.response?.data;
    resMessage.status = "error";
    dispatch({ type: "USER_REGISTRATION_ERROR", payload: resMessage });
    return resMessage;
  }
};

const subscribleClient = async (dispatch, creds) => {
  try {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    dispatch({ type: "START_SPINNER" });
    let result = await request.post("/users/register/modules", creds);
    result = result.data;
    result.status = "success";
    dispatch({ type: "STOP_SPINNER" });
    dispatch({ type: "USER_SUBSCRIBLE_SUCCESS", payload: result });

    return result;
  } catch (error) {
    dispatch({ type: "STOP_SPINNER" });
    let resMessage = error?.response?.data;
    resMessage.status = "error";
    dispatch({ type: "USER_SUBSCRIBLE_ERROR", payload: resMessage });
    return resMessage;
  }
};
const unSubscribleClient = async (dispatch, creds) => {
  try {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    dispatch({ type: "START_SPINNER" });
    let result = await request.get(
      `/users/modules/${creds.bid}/cancel-pending-subscription`
    );
    result = result.data;
    result.status = "success";
    dispatch({ type: "STOP_SPINNER" });
    dispatch({ type: "USER_UNSUBSCRIBLE_SUCCESS", payload: result });

    return result;
  } catch (error) {
    dispatch({ type: "STOP_SPINNER" });
    let resMessage = error?.response?.data;
    resMessage.status = "error";
    dispatch({ type: "USER_UNSUBSCRIBLE_ERROR", payload: resMessage });
    return resMessage;
  }
};

const subscribleClientNow = async (dispatch, url) => {
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  // dispatch({ type: "START_SPINNER" });
  window.location.href = url;
};

const setFilterModules = async (dispatch, text) => {
  dispatch({ type: "SET_FILTER_TEXT", payload: text });
};

export {
  registerClient,
  subscribleClient,
  subscribleClientNow,
  unSubscribleClient,
  setFilterModules,
};
