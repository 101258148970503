import { useState, useEffect } from "react";
import styles from "../../styles/PaymentOptions.module.css";
import { MdPayments } from "react-icons/md";
import { useShallowEqualSelector } from "../../hooks";
import { selected_modules } from "../../selectors/userSelectors";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";

import queryString from "query-string";

function PaymentOptions(props) {
  const [channel, setChannel] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [sel_id, setSelId] = useState([]);

  const selected = useShallowEqualSelector(selected_modules);

  const dispatch = useDispatch();

  const params = queryString.parse(useLocation().search);

  useEffect(() => {
    if (selected?.length) {
      const id = [];
      selected.forEach((modules) => {
        id.push({ id: modules.id, no_of_users: modules.no_of_users });
      });
      setSelId(id);

      dispatch({ type: "SET_SELECTED_MODULES", payload: id });
      dispatch({ type: "SET_BUSINESS_ID", payload: params.bId });
    }
  }, [selected, dispatch, params.bId]);

  useEffect(() => {
    if (channel) {
      dispatch({ type: "SET_SELECTED_CHANNEL", payload: channel });
    }
  }, [channel, dispatch]);

  return (
    <>
      <div
        className={`${styles["payment-options"]} d-block col payment-options py-0`}
      >
        <div className="px-3 pt-2 d-flex justify-content-between align-items-center">
          <div className={`${styles.heading} pt-2`}>
            <p className="my-1 text-muted">Select payment option</p>
          </div>
          <div className={`${styles.icon} mr-0`}>
            <MdPayments />
          </div>
        </div>
        <hr />
        <div className={styles.content}>
          <div className={styles["payment-group"]}>
            <div
              className={styles["payment-method"]}
              onClick={() => setChannel("paystack")}
            >
              <p>1. Paystack</p>
              <div
                className="d-flex w-100 gap-4 align-items-center justify-content-start px-4"
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  name="paymentMethod"
                  value="paystack"
                  checked={channel === "paystack"}
                />
                <img
                  src="assets/payment-logos/Paystack Logo.svg"
                  alt="paystack"
                  className={styles.paystack}
                />
              </div>
            </div>
            <div
              className={styles["payment-method"]}
              style={{opacity: 0.5}}
              // onClick={() => setChannel("interswitch")}
            >
              <p>2. Interswitch</p>
              <div
                className="d-flex w-100 gap-4 align-items-center justify-content-start px-4"
                style={{ cursor: "not-allowed" }}
              >
                <input
                  type="radio"
                  name="paymentMethod"
                  value="interswitch"
                  checked={channel === "interswitch"}
                  disabled
                />
                <img
                  src="assets/payment-logos/Interswitch Logo.svg"
                  alt="Interswitch"
                />
              </div>
            </div>
            <div
              className={styles["payment-method"]}
              style={{opacity: 0.5}}
              // onClick={() => setChannel("flutterwave")}
            >
              <p>3. Flutterwave</p>
              <div
                className="d-flex w-100 gap-4 align-items-center justify-content-start px-4"
                style={{ cursor: "not-allowed" }}
              >
                <input
                  type="radio"
                  name="paymentMethod"
                  value="futterwave"
                  checked={channel === "flutterwave"}
                  disabled
                />
                <img
                  src="assets/payment-logos/Flutterwave Logo.svg"
                  alt="Flutterwave"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentOptions;
