import React from "react";
import styles from "../../styles/Modal.module.css";

export default function Modal(props) {
  return (
    <>
      <div className={styles.backdrop} onClick={props.onClose}>
        <div
          className={`${styles.modal} ${props.className}`}
          onClick={(e) => e.stopPropagation()}
        >
          {props.children}
        </div>
      </div>
    </>
  );
}
