import { useEffect } from "react";
import Confetti from "react-confetti";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { useWindowSize } from "../../hooks";
import Logo from "./Logo";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { request } from "../../requestMethods";
// import { notificationSuccess } from "../util/helpers";

function SuccessPage() {
  const { width, height } = useWindowSize();
  const params = queryString.parse(useLocation().search);

  // const navigate = useNavigate()

  useEffect(() => {
    const reference = params.reference;
    if (reference) {
      request
        .get(`/validate-payment?trxref=${reference}`)
        .then((response) => {
          let status = response.data.status;

          if (status) {
            const body = response.data;
            return request.post("/paystack-webhook", body);
          }
        })
        .then((result) => {
          console.log(result.data.message);
        })
        .catch((error) => console.log(error));
    }
  }, [params.reference]);

  // clear storage
  useEffect(() => {
    const current_cart = window.localStorage.getItem("cart");

    if (current_cart) {
      window.localStorage.removeItem("cart");
    }
  }, []);
  // const [countdown, setCountDown] = useState(5);

  // useEffect(() => {
  //   setInterval(() => setCountDown(countdown - 1), 1000);
  //   setTimeout(() => {
  //     window.location.replace("https://ssl-login-app.vercel.app/");
  //   }, 5000);
  // }, [countdown]);

  return (
    <div className="container-scroller">
      <Confetti width={width} height={height} />
      <div className="row">
        <div className="col-12 col-md-6 bg-light p-0 mx-auto">
          <div className="container-fluid page-body-wrapper full-page-wrapper px-0">
            <div className="content-wrapper d-flex flex-column px-4 px-lg-5 py-5">
              <div className="container-fluid h-100 d-flex align-items-center px-0">
                <div className="row px-sm-0 w-100 mx-auto">
                  <div className="col-12 mb-lg-0 d-flex flex-column justify-content-center align-items-center gap-3">
                    <Logo />
                    <h1 className="mt-5">Congratulations</h1>
                    <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                      <span style={{ fontSize: "4rem" }}>
                        <FaMoneyCheckAlt />
                      </span>
                      <h3 className="text-center">
                        Payment processed successfully
                      </h3>
                      <p className="text-center">
                        Kindly refer to your email to activate and login to your
                        account
                      </p>
                      {/* <small className="text-center">
                        You would be redirected to the login page in {countdown}{" "}
                        seconds
                      </small> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
