import { notification } from "antd";

function notificationError(description, duration = 6) {
  notification.error({
    message: "Oops! something went wrong",
    description: description,
    duration,
  });
}
function notificationSuccess(description, duration = 6) {
  notification.success({
    message: "Success",
    description: description,
    duration,
  });
}
function formatNumbers(number) {
  const formattedNumber = Number(number)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedNumber;
}

function convertToCapitalize(sentence) {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

function slugify(str) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "_")
    .replace(/^-+|-+$/g, "");
}

export {
  notificationError,
  notificationSuccess,
  formatNumbers,
  convertToCapitalize,
  slugify,
};
