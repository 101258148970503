import "./App.css";
import { Routes, Route } from "react-router-dom";
import Register from "./components/Guest/Register";
import Modules from "./components/Guest/Modules";
import SuccessPage from "./components/Guest/SuccessPage";
import Invoice from "./components/Guest/Invoice";

function App() {
  return (
    <>
      <Routes>
        <Route index element={<Register />} />
        <Route path="/modules" element={<Modules />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </>
  );
}

export default App;
