import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";

import { request } from "../../requestMethods";

const useGetSystemModules = (enabled, setEnabled) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_modules"],
    async () => {
      let result = await request.get(`/users/modules`);
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 0 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "SYSTEM_MODULES_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["system_modules"]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "SYSTEM_MODULES_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

const useGetClientSubscriptionDetails = (enabled, setEnabled, bid) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["client_sub_details"],
    async () => {
      let result = await request.get(
        `/users/modules/${bid}/recent-subscription`
      );
      result = result.data;
      result.status = "success";
      return result;
    },
    { enabled: enabled, manual: true, retry: 1 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER" });
     
      dispatch({ type: "CLIENT_SUB_DETAILS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      setEnabled(false);
      queryClient.removeQueries(["client_sub_details"]);
      let resMessage = error?.response?.data;
      resMessage.status = "error";
      dispatch({ type: "STOP_SPINNER" });
      dispatch({ type: "CLIENT_SUB_DETAILS_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled, queryClient]);

  return { data, refetch };
};

export { useGetSystemModules, useGetClientSubscriptionDetails };
