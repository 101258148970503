const currentUser = {};

const initState = {
  message: null,
  status: null,
  spinner: false,
  currentUser,
  business_id: null,
  errors: {},
  system_modules: [],
  sel_id: [],
  subscription_details: {},
  sel_channel: null,
  duration: 56,
  no_of_users: null,
  filter_text: "",
};

const userReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "CLEAR_USERS_ERRORS":
      return {
        ...state,
        message: null,
        status: null,
        errors: {},
      };
    case "START_SPINNER":
      return {
        ...state,
        spinner: true,
      };
    case "STOP_SPINNER":
      return {
        ...state,
        spinner: false,
      };
    case "USER_REGISTRATION_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        business_id: payload.payload.business_id,
      };
    case "SAVE_NO_OF_EMPLOYEES":
      return {
        ...state,
        no_of_users: payload,
      };
    case "SAVE_COMPANY_EMAIL":
      return {
        ...state,
        company_email: payload,
      };
    case "USER_REGISTRATION_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "USER_UNSUBSCRIBLE_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "USER_UNSUBSCRIBLE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "USER_SUBSCRIBLE_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        subscription_details: { ...payload.payload },
      };
    case "USER_SUBSCRIBLE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "SYSTEM_MODULES_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        system_modules: payload.data,
      };
    case "SYSTEM_MODULES_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "CLIENT_SUB_DETAILS_SUCCESS":
      return {
        ...state,
        subscription_details: { ...payload.payload },
      };
    case "CLIENT_SUB_DETAILS_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "SET_SELECTED_MODULES":
      return {
        ...state,
        sel_id: payload,
      };
    case "SET_BUSINESS_ID":
      return {
        ...state,
        business_id: payload,
      };
    case "SET_SELECTED_CHANNEL":
      return {
        ...state,
        sel_channel: payload,
      };
    case "SET_FILTER_TEXT":
      return {
        ...state,
        filter_text: payload,
      };
    // case "UPDATE_PARENT": {
    //   console.log(payload);
    //   const indexOfExistingModule = state.system_modules.findIndex(
    //     (module) => module.id === payload.id
    //   );

    //   const foundModuleItem = state.system_modules[indexOfExistingModule];
    //   const updatedNumOfUsers = Number(payload.no_of_users) + 1;
    //   let newModules;

    //   const updatedModule = {
    //     ...foundModuleItem,
    //     no_of_users: updatedNumOfUsers,
    //   };
    //   newModules = [...state.system_modules];
    //   newModules[indexOfExistingModule] = updatedModule;
    //   return {
    //     ...state,
    //     system_modules: newModules,
    //   };
    // }

    default:
      return state;
  }
};

export default userReducer;
