import { useState } from "react";
import { GoSearch } from "react-icons/go";
import { setFilterModules } from "../../redux/users/userActions";
import "../../styles/containerScroller.css";
import { useDispatch } from "react-redux";

function Searchbar({ placeholder, dark }) {
  const [search_text, setSearchText] = useState();
  const dispatch = useDispatch();

  function handleSearch(e) {
    e.preventDefault();
    setSearchText(e.target.value);
    setFilterModules(dispatch, e.target.value);
  }

  return (
    <div className={`${dark && "searchbar-dark"} searchbar px-1 my-3`}>
      <GoSearch />
      <span className="h4">|</span>
      <input
        type="text"
        className="w-100 px-1"
        placeholder={placeholder}
        value={search_text}
        onChange={handleSearch}
      />
    </div>
  );
}

export default Searchbar;
